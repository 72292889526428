import './styles/global.scss'
import './styles/fonts/connect.scss'

import { useGSAP } from '@gsap/react'
import { setDebug, useLaunchParams } from '@telegram-apps/sdk-react'
import gsap from 'gsap'
import { type FC, useEffect } from 'react'

import { useBackButtonCloseApp } from '@/features/backButton'
import { LoginGate } from '@/features/login'
import { AppLoader } from '@/shared/ui'

import { AppRouter, ErrorBoundarySDK, withProviders } from './providers'
import { ExpandedProvider } from './providers/expanded'
import { withComplementaryProviders } from './providers/withProviders'

gsap.registerPlugin(useGSAP)

const AppWithComplementaryProviders = withComplementaryProviders(AppRouter)

const AppContent: FC = () => {
  useBackButtonCloseApp()
  const lp = useLaunchParams()

  useEffect(() => {

    if (!import.meta.env.DEV && !import.meta.env?.['VITE_DOMAIN'].includes('dev')) return

    if (lp.startParam === '-1') {
      setDebug(true)
      import('eruda').then(it => it.default.init())
    }
  }, [])

  return (
    <ExpandedProvider>
      <LoginGate error={ErrorBoundarySDK} loading={AppLoader} initial={AppLoader}>
        <AppWithComplementaryProviders />
      </LoginGate>
    </ExpandedProvider>
  )
}

export const App = withProviders(AppContent)
